import React from "react";
import { SecondaryText } from "../../../../../../../../global/components/SecondaryText/SecondaryText";
import { Box, Typography } from "@mui/material";
import { UploadedImage } from "./UploadedImage";
import { IPatientUploads } from "./types/IPatientUploads";

export const PatientUploads = ({ uploadedFiles }: IPatientUploads) => {
  if (!uploadedFiles.length) return null;

  return (
    <>
      <SecondaryText pt={2}>Patient Uploads</SecondaryText>
      <Box display="flex" gap={2} pt={1} flexWrap="wrap">
        {uploadedFiles.map((file) => (
          <Box key={file.id} display="flex" flexDirection="column" gap={1}>
            <Typography
              sx={{
                fontSize: ".95rem !important",
                maxWidth: 170,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              title={file.name}
            >
              {file.name}
            </Typography>
            <UploadedImage src={file.smallPreviewUrl} alt={file.name} />
          </Box>
        ))}
      </Box>
    </>
  );
};
