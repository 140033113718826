import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { INITIAL_FORM_POST_BODY } from "./initialBodyForm";
import { FormEditorProperties } from "./formEditorProperties.interface";
import { FormResponse } from "./formResponse.interface";
import { FormApiResponse } from "./FormsApiResponse.interface";
import { Response } from "../Response.interface";
import { Source } from "../../../../../types/settings/formBuilder/source.interface";
import { IPmsInformationItem } from "../../../../../types/settings/formBuilder/IPmsInformationItem";
import { IFormData } from "../FormTable.interface";
import { IFormProperties } from "./IFormProperties";
import { IForm } from "../../../../../types/settings/formBuilder/IForm.interface";
import { IErrorResponse } from "../../../../../types/apiResponse/IErrorResponse.interface";
import { ISaveFormRequest } from "../../../../../types/settings/formBuilder/ISaveFormRequest.interface";
import { baseQueryWithReAuth } from "../../../../../helperFunctions/api/baseQueryWithReauth";
import { TEN_MINUTES_IN_S } from "../../../../../constants";
import { FORMS_TAG } from "../../../../formsTag";
import { downloadFile } from "../../../../../helperFunctions/fileConverter/convertBlobToOtherFormats";
import { removeSpecialCharacters } from "../../../../../helperFunctions/text/removeSpecialCharacters";
import { defaultFormsTableSorting } from "../../../../../constants/defaultFormsTableSorting";
import {
  updateSnackbar,
  updateErrorSnackbar,
} from "../../../../../../features/authentication/globalMessagesSlice";
import {
  IFormAutomationInterval,
  IUpdateAutomationForm,
  IUpdateAutomationPackage,
} from "./automationModalRequest.interfaces";
import { IFormLibraryQueryParams } from "../../../../../types/settings/formBuilder/IFormLibraryQueryParams";
import { getSortingUrlParams } from "../../../../../helperFunctions/query/getSortingUrlParams";
import { getSkipUrlParams } from "../../../../../helperFunctions/query/getSkipUrlParams";
import { getUrlParamsFromArray } from "../../../../../helperFunctions/query/getUrlParamsFromArray";
import { EActiveStatus } from "../../../../../enums/EActiveStatus";

const FORM_EDITOR_TAG = "FormEditor";
const FORM_TAG = "Form";
const ALL_ACTIVE_FORMS_TAG = "allActiveFormsTag";
export const PACKAGES_TAG = "Packages";

export const formEditorApi = createApi({
  reducerPath: "formEditorApi",
  tagTypes: [
    FORM_EDITOR_TAG,
    FORM_TAG,
    FORMS_TAG,
    ALL_ACTIVE_FORMS_TAG,
    PACKAGES_TAG,
  ],
  baseQuery: baseQueryWithReAuth(process.env.REACT_APP_YAPI_DATAFORMS),
  endpoints: (build) => ({
    createForm: build.mutation<number, void>({
      query: () => {
        return {
          url: `/form`,
          method: "POST",
          body: INITIAL_FORM_POST_BODY,
        };
      },
      invalidatesTags: [FORM_EDITOR_TAG],
      transformResponse: (response: { id: number }) => response.id,
    }),

    deleteForm: build.mutation<null, string | number>({
      query(id) {
        return {
          url: `Form/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [FORMS_TAG],
    }),

    updateFormProperties: build.mutation<
      unknown,
      { id: string; body: ISaveFormRequest }
    >({
      async queryFn(args, api, _extraOptions, fetchWithBQ) {
        const { id, body } = args;
        const response = await fetchWithBQ({
          url: `/Form/${id}?api-version=1`,
          method: "PATCH",
          body,
        });

        const data = response?.data as IForm;
        const error = response?.error as IErrorResponse;

        if (data?.id) {
          api.dispatch(
            updateSnackbar({
              status: true,
              message: "Page has been updated successfully",
            })
          );
        } else {
          api.dispatch(
            updateErrorSnackbar({
              status: true,
              message: error?.data?.Message,
            })
          );
        }

        return response;
      },
      invalidatesTags: [FORM_TAG],
    }),

    getForms: build.query<FormApiResponse, Partial<IFormLibraryQueryParams>>({
      query: ({
        page,
        pageSize,
        sorting,
        statuses,
        languages,
        categories,
        search,
      }) => {
        const skip: number = getSkipUrlParams(page, pageSize);
        const orderBy: string = getSortingUrlParams(sorting);

        const url = `/odata/Forms?$count=true&$orderby=${orderBy}&$take=${pageSize}&$skip=${skip}`;

        const searchQueryParams: string = search
          ? `&$name=${search}&$applyNameEquality=false`
          : "";

        const filtersQueryParams: string =
          getUrlParamsFromArray("category", categories) +
          getUrlParamsFromArray("language", languages) +
          getUrlParamsFromArray("activeStatus", statuses);

        return {
          url: url + searchQueryParams + filtersQueryParams,
          method: "GET",
        };
      },

      providesTags: [FORMS_TAG, FORM_TAG],
      // need to switch off the cache for this endpoint, because when user click on New Form button on a small screen,
      // after that click on Go Back button, empty form will be deleted during unmount form builder component. But,
      // mount of form table component can be start more quickly than delete form query will be finished. In this case
      // getForms query will start earlier than deleteForm finished. It's provide an issue, that forms table will be not
      // updated after form deleting, because new getForms request after deleting will be tried to start with same
      // params that initial request during mount and will take data from the cache
      keepUnusedDataFor: 0,
    }),

    getAllActiveForms: build.query<IFormData[], void>({
      query: () =>
        `odata/Forms?$activeStatus=3&$orderby=${defaultFormsTableSorting}`,
      providesTags: [ALL_ACTIVE_FORMS_TAG],
    }),

    checkFormExists: build.query<FormResponse[], string>({
      query(name) {
        return {
          url: `/odata/forms?$name=${name}&$take=1&$applyNameEquality=true`,
          method: "GET",
        };
      },
    }),

    getSources: build.query<Source[], void>({
      query() {
        return {
          url: "/source",
          method: "GET",
        };
      },
    }),

    getSource: build.query<IPmsInformationItem[], number>({
      query(id) {
        return {
          url: `/source/${id}/viewer`,
          method: "GET",
        };
      },
    }),

    getFormProperties: build.query<IFormProperties, string>({
      query(id) {
        return {
          url: `/Form/${id}?api-version=1`,
          method: "GET",
        };
      },
      providesTags: [FORM_TAG],
      transformResponse: (response: FormEditorProperties) => {
        return {
          importCategory: response.pmsImportDestination,
          formCategory: response.category,
          formName: response.name,
          sourceType: response.sourceType,
          isImported: response.isImported,
          hasResponses: response.hasResponses,
        };
      },
    }),

    updateFormVersion: build.mutation<IFormData, number>({
      query(formId) {
        return {
          url: `Form/${formId}/version`,
          method: "POST",
        };
      },
      invalidatesTags: [FORMS_TAG],
    }),

    getForm: build.query<FormResponse, { id: number; token: string }>({
      query: (params) => `/Form/${params.id}?token=${params.token}`,
    }),

    getResponse: build.query<Response, string>({
      query: (responseId) => `/form/response/${responseId}`,
    }),

    changeStatus: build.mutation<
      FormResponse,
      { id: number; activeStatus: EActiveStatus }
    >({
      query({ id, activeStatus }) {
        return {
          url: `/Form/${id}`,
          method: "PATCH",
          body: { activeStatus },
        };
      },
      invalidatesTags: [FORMS_TAG, PACKAGES_TAG],
    }),

    duplicateForm: build.mutation<
      FormResponse,
      { id: number; payload: unknown }
    >({
      query({ id }) {
        return {
          url: `/Form/${id}/clone`,
          method: "POST",
          body: { id },
        };
      },
      invalidatesTags: [FORMS_TAG],
    }),

    exportForm: build.query<unknown, { id: number; name: string }>({
      async queryFn(args, api, extraOptions, fetchWithBQ) {
        const { id, name } = args;
        const resp = await fetchWithBQ({
          url: `/form/${id}/export`,
          method: "GET",
          responseHandler: (response) => response.blob(),
        });

        if (resp?.data) {
          downloadFile(resp?.data, removeSpecialCharacters(name), "zip");
        }

        return resp;
      },
    }),

    getLatestForm: build.query<FormResponse, string | number>({
      query(id) {
        return {
          url: `/Form/latest/${id}`,
          method: "GET",
        };
      },
    }),

    getFormAutomationIntervals: build.query<IFormAutomationInterval[], void>({
      query: () => "formAutomation/intervals",
    }),

    updateAutomationForm: build.mutation<void, IUpdateAutomationForm>({
      query: ({ formId, intervalId, procedureCodes }) => ({
        url: `formAutomation/${formId}/settings`,
        method: "PATCH",
        body: {
          intervalId,
          procedureCodes,
        },
      }),
      invalidatesTags: [FORMS_TAG],
    }),

    updateAutomationPackage: build.mutation<void, IUpdateAutomationPackage>({
      query: ({ packageId, intervalId, procedureCodes }) => ({
        url: `packages/${packageId}`,
        method: "PATCH",
        body: {
          intervalId,
          procedureCodes,
        },
      }),
      invalidatesTags: [PACKAGES_TAG],
    }),
  }),
  keepUnusedDataFor: TEN_MINUTES_IN_S,
});

export const {
  useUpdateAutomationPackageMutation,
  useUpdateAutomationFormMutation,
  useGetFormAutomationIntervalsQuery,
  useCreateFormMutation,
  useUpdateFormPropertiesMutation,
  useGetFormsQuery,
  useLazyGetFormsQuery,
  useLazyCheckFormExistsQuery,
  useGetFormPropertiesQuery,
  useLazyGetFormPropertiesQuery,
  useDeleteFormMutation,
  useChangeStatusMutation,
  useLazyGetFormQuery,
  useDuplicateFormMutation,
  useGetAllActiveFormsQuery,
  useGetSourcesQuery,
  useLazyGetSourceQuery,
  useGetFormQuery,
  useLazyGetResponseQuery,
  useLazyExportFormQuery,
  useUpdateFormVersionMutation,
  useLazyGetLatestFormQuery,
} = formEditorApi;
