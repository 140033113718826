import { styled } from "@mui/material";

export const SImageWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.secondary.dark,
  borderRadius: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 170,
  height: 170,
  "& img": {
    objectFit: "contain",
    overflow: "hidden",
    minWidth: 35,
  },
}));
