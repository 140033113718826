import React from "react";
import staticImage from "../../../../../../../../assets/image.svg";
import { SImageWrapper } from "./styles";

export const UploadedImage = ({ alt, src }) => {
  const openInNewWindow = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    window.open(src);
  };

  const isWaitingForBEImageImplementation = true;
  const imgSrc = isWaitingForBEImageImplementation ? staticImage : src;

  return (
    <SImageWrapper>
      <img src={imgSrc} alt={alt} onClick={openInNewWindow} />
    </SImageWrapper>
  );
};
