import { ENewMessage } from "../types/communications/ENewMessage.enum";
import { EPendingFormAssignmentSortBy } from "../types/documentManager/EPendingFormAssignmentSortBy.enum";
import { ESortOrder } from "../types/documentManager/ESortOrder.enum";
import { PATH_OF } from "../../features/emailCampaigns/helpers/constants";

// Initial load routes
const ROOT = "/";
const MULTILOCATION = "/multilocation";
const DASHBOARD = "/dashboard";
const FILL_APPT = "fill_appointment";
const HOME = {
  ROOT: DASHBOARD,
  FILL_APPOINTMENT_MANUAL: `${DASHBOARD}/${FILL_APPT}`,
  fillAppointment: (id: string) => `${DASHBOARD}/${FILL_APPT}/${id}`,
};
const LOADING_SCREEN = "/loading";
const UNAUTHORIZED = "/unauthorized";

// Routes in order from side nav
// *** Patient Finder ***
const PATIENT_FINDER = "/patientFinder";

const PATIENT_PROFILE = "/patientProfile";

const PatientProfile = {
  ROOT: PATIENT_PROFILE,
  appointments: (id: number | string) =>
    `${PATIENT_PROFILE}/${id}/appointments`,
  emailHistory: (id: number | string) =>
    `${PATIENT_PROFILE}/${id}/emailHistory`,
  textMessages: (id: number | string) =>
    `${PATIENT_PROFILE}/${id}/textMessages`,
  summary: (id: number | string) => `${PATIENT_PROFILE}/${id}/summary`,
  insurance: (id: number | string) => `${PATIENT_PROFILE}/${id}/insurance`,
} as const;

const PROFILE = "/profile";
const appointment = (id: number | string) => `/appointment/${id}`;
const composePatientEmail = (id: number | string) =>
  `/composePatientEmail/${id}`;
const preferences = (id: number | string) => `/preferences/${id}`;

// *** Email Campaigns ***
const EMAIL_CAMPAIGNS = "/emailCampaigns";
const EmailCampaigns = {
  ROOT: EMAIL_CAMPAIGNS,
  EMAIL_CAMPAIGNS_LIST: `${EMAIL_CAMPAIGNS}?pageSize=5&pageIndex=0&statusFilter=all`,
  createCampaigns: (activeStep: PATH_OF) =>
    `${EmailCampaigns.ROOT}/createCampaign/${activeStep}`,
  emailCampaignsList: (
    pageIndex: number,
    pageSize: number,
    statusFilter: string
  ) =>
    `${EmailCampaigns.ROOT}?pageSize=${pageSize}&pageIndex=${pageIndex}&statusFilter=${statusFilter}`,
};

// *** Patient Communication ***

// Text Templates
const TEXT_MESSAGES = "/TextMessages";
const TextMessages = {
  ROOT: TEXT_MESSAGES,
  COMPOSE: `${TEXT_MESSAGES}/patient/${ENewMessage.COMPOSING}`,
  PATIENT: `${TEXT_MESSAGES}/patient/menu`,
  MASS_TEXT: `${TEXT_MESSAGES}/mass/menu`,
  detailByPatientId: (patientId: string) =>
    `${TextMessages.ROOT}/patient/${patientId}`,
} as const;

const CREATE_MASS_TEXT = "/createMassText";

const TEMPLATE_CREATOR = "/templateCreator";
// Text Templates
const TEXT_TEMPLATE = "/textTemplates";
const TextTemplates = {
  ROOT: TEXT_TEMPLATE,
  COMPOSE: `${TEXT_TEMPLATE}/compose`,
  editTextTemplate: (id: string) => `${TextTemplates.COMPOSE}/${id}`,
} as const;

const PATIENT_MESSENGER = "/patientMessenger";

// *** Document Manager ***

const DocumentManager = {
  ROOT: "/document-manager",
  pendingForms: (
    sortField: EPendingFormAssignmentSortBy,
    sortOrder: ESortOrder
  ) =>
    `${DocumentManager.ROOT}/pendingForms?&sortOrder=${sortOrder}&sortField=${sortField}`,
  inOfficePatients: () => `${DocumentManager.ROOT}/inOfficePatients`,
  outstandingTasks: () => `${DocumentManager.ROOT}/outstandingTasks`,
  signaturePreview: (formId, responseId) =>
    `${DocumentManager.ROOT}/${formId}/signature/${responseId}`,
  pendingFormPreview: (formId, responseId): string =>
    `${DocumentManager.ROOT}/${formId}/pending_form/preview/${responseId}`,
  inOfficeFormPreview: (formId, responseId): string =>
    `${DocumentManager.ROOT}/${formId}/in_office_form/preview/${responseId}`,
  outstandingTaskFormPreview: (formId, responseId): string =>
    `${DocumentManager.ROOT}/${formId}/outstanding_task_form/preview/${responseId}`,
} as const;

// *** Payments ***

const PAYMENTS = "/payments";
const PAYMENT_REQUEST = "/paymentRequest";

// *** Reports ***
const REPORTS = "/reports";
const Reports = {
  ROOT: REPORTS,
  OnlineScheduling: {
    ROOT: `${REPORTS}/onlineScheduling`,
  },
} as const;

// *** Settings ***

// Users
const USERS = "/users";
const Users = {
  ROOT: USERS,
  ACTIVE:
    "/users/active/users?criteria=byName&value=&statuses=active&pageIndex=0&pageSize=10&sortField=FirstName&sortOrder=asc",
  DEACTIVATED:
    "/users/deactivated/users?criteria=byName&value=&statuses=deactivated&pageIndex=0&pageSize=10&sortField=FirstName&sortOrder=asc",
  UNLINKED:
    "/users/unlinked/users?linkStatus=unlinked&criteria=name&value=&pageIndex=0&pageSize=10&sortField=FirstName&sortOrder=asc",
  PENDING:
    "/users/pending/users?criteria=byName&value=&statuses=pending&pageIndex=0&pageSize=10&sortField=FirstName&sortOrder=asc",
} as const;
const INVITE_NEW_USER = "/inviteNewUser";

// Practice Settings
const PRACTICE_SETTINGS = "/practiceSettings";
const PATIENT_COMMUNICATION = `${PRACTICE_SETTINGS}/patientCommunication`;
const APPOINTMENTS = `${PRACTICE_SETTINGS}/onlineScheduling/appointments`;
const FORMS_LIBRARY = `${PRACTICE_SETTINGS}/formsLibrary`;
const TEMPLATE_LIBRARY = `${PRACTICE_SETTINGS}/templateLibrary`;
const REVIEWS_SETTINGS = `${PRACTICE_SETTINGS}/reviews`;
const INSURANCE_VERIFICATION_SETTINGS = `${PRACTICE_SETTINGS}/insuranceVerification`;
export const FORM_TEMPLATES = `${TEMPLATE_LIBRARY}/formTemplates`;
const IN_OFFICE_DEVICE_SETTINGS = `${PRACTICE_SETTINGS}/inOfficeDeviceSettings`;
const PracticeSettings = {
  ROOT: PRACTICE_SETTINGS,
  PatientCommunication: {
    ROOT: PATIENT_COMMUNICATION,
    SETTING: `${PRACTICE_SETTINGS}/patientCommunication/setting`,
  },
  OnlineScheduling: {
    ROOT: `${PRACTICE_SETTINGS}/onlineScheduling`,
    PROVIDERS: `${PRACTICE_SETTINGS}/onlineScheduling/providers?statusFilter=All&pageIndex=0&pageSize=10&sortField=ByName&sortOrder=asc`,
    Appointments: {
      ROOT: APPOINTMENTS,
      TREATMENT_DETAILS: `${APPOINTMENTS}/treatmentDetails`,
      ADD_TREATMENT: `${APPOINTMENTS}/addTreatment`,
      editTreatment: (id: number | string) =>
        `${APPOINTMENTS}/editTreatment/${id}`,
      OPERATORIES: `${APPOINTMENTS}/operatories`,
      ACCEPTED_INSURANCE: `${APPOINTMENTS}/acceptedInsurance/PracticeInsurances?pageIndex=0&insuranceName=&isPopularOnly=false&insuranceStatus=all`,
    },
    GENERAL_SETTINGS: `${PRACTICE_SETTINGS}/onlineScheduling/generalSettings`,
  },
  PracticeInformation: {
    ROOT: `${PRACTICE_SETTINGS}/practiceInformation`,
    PmsIntegrationTab: `${PRACTICE_SETTINGS}/practiceInformation/pmsTab`,
    GeneralInformationTab: `${PRACTICE_SETTINGS}/practiceInformation/generalInformation`,
  },
  PatientReminders: {
    ROOT: `${PRACTICE_SETTINGS}/patientReminders`,
    RecallTab: `${PRACTICE_SETTINGS}/patientReminders/recall`,
    AppointmentTab: `${PRACTICE_SETTINGS}/patientReminders/appointment`,
    editTextTemplate: (id: string, reminderCategory: string) =>
      `${PRACTICE_SETTINGS}/patientReminders/textTemplates/compose/${id}/${reminderCategory}`,
  },
  FormsLibrary: {
    ROOT: FORMS_LIBRARY,
    packages: `${FORMS_LIBRARY}/packages`,
    formsTable: `${FORMS_LIBRARY}/forms`,
    formEditor: (formId: string | number) =>
      `${FORMS_LIBRARY}/${formId}/editor`,
    formPreview: (formId: string | number) =>
      `${FORMS_LIBRARY}/${formId}/preview`,
    inOfficeDeviceSettings: IN_OFFICE_DEVICE_SETTINGS,
  },
  TemplateLibrary: {
    ROOT: TEMPLATE_LIBRARY,
    textTemplates: {
      ROOT: `${TEMPLATE_LIBRARY}${TEXT_TEMPLATE}`,
      COMPOSE: `${TEMPLATE_LIBRARY}${TEXT_TEMPLATE}/compose`,
      editTextTemplate: (id: string): string =>
        `${PracticeSettings.TemplateLibrary.textTemplates.COMPOSE}/${id}`,
    },
    formTemplates: FORM_TEMPLATES,
    templatePreview: (templateId: number): string =>
      `${TEMPLATE_LIBRARY}/${templateId}/preview`,
  },
  schedulingWidget: (guid: number | string) =>
    `${PRACTICE_SETTINGS}/schedulingWidget/${guid}`,
  Reviews: {
    ROOT: REVIEWS_SETTINGS,
  },
  InsuranceVerification: {
    ROOT: INSURANCE_VERIFICATION_SETTINGS,
  },
} as const;

const editPMSUser = (id: number | string, section: number | string) =>
  `/editPmsUser/${id}/${section}`;
const editUser = (id: number | string) => `/editUser/${id}`;
const LINK_PMS_USER = "linkPmsUser";

const editProvider = (id: number | string) => `/editProvider/${id}`;

//Help Resources

const HELP_RESOURCES = "/helpResources";

// Bottom Side Nav

const SWITCH_LOCATION = "/switchLocation";
const LOGOUT = "/logout";

// Routes with children
const routeSections = {
  PatientProfile,
  PracticeSettings,
  EmailCampaigns,
  TextMessages,
  TextTemplates,
  Users,
  Reports,
  DocumentManager,
} as const;

// Routes with arguments
const parameterRoutes = {
  appointment,
  composePatientEmail,
  editPMSUser,
  editUser,
  editProvider,
  preferences,
} as const;

// *** FINAL ROUTES ***
const finalRoutes = {
  CREATE_MASS_TEXT,
  HOME,
  LOADING_SCREEN,

  INVITE_NEW_USER,
  LINK_PMS_USER,

  LOGOUT,
  MULTILOCATION,
  PAYMENTS,
  PAYMENT_REQUEST,
  PATIENT_FINDER,
  PATIENT_MESSENGER,
  PROFILE,

  EMAIL_CAMPAIGNS,

  HELP_RESOURCES,

  UNAUTHORIZED,

  SWITCH_LOCATION,

  TEMPLATE_CREATOR,

  ROOT,
} as const;

export default {
  ...parameterRoutes,
  ...routeSections,
  ...finalRoutes,
} as const;
